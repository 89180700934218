import { type GetStaticProps } from 'next';

import { type INotFoundPageProps } from '../modules/notFound/pageProps/@interfaces';

import { NotFoundTemplate } from '../templates';
import { localizedContentService } from '../services';
import { modifiers } from '../data/shared';

const NotFound = (props: INotFoundPageProps) => <NotFoundTemplate {...props} />;

export const getStaticProps: GetStaticProps = async () => {
  const { footerContent, headerContent } =
    localizedContentService<INotFoundPageProps>(
      'pt-BR',
      ['footerContent', 'headerContent'],
      [modifiers.STATIC_ASSETS_PATH, modifiers.CURRENT_YEAR]
    );

  return {
    props: {
      footerContent,
      headerContent,
    },
  };
};

export default NotFound;
